




































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    }),
    images01(): string[] {
      return [
        this.oss.am_rgb_65_swiper_01,
        this.oss.am_rgb_65_swiper_02,
        this.oss.am_rgb_65_swiper_03,
        this.oss.am_rgb_65_swiper_04,
        this.oss.am_rgb_65_swiper_05
      ];
    },
    names01(): string[] {
      return this.isCN
        ? ["@Vincent树", "@Vincent树", "@Vincent树", "@Vincent树", "@Vincent树"]
        : [
            "@Vincent树",
            "@Vincent树",
            "@Vincent树",
            "@Vincent树",
            "@Vincent树"
          ];
    },
    images02(): string[] {
      return [
        this.oss.am_rgb_65_swiper_06,
        this.oss.am_rgb_65_swiper_07,
        this.oss.am_rgb_65_swiper_08,
        this.oss.am_rgb_65_swiper_09,
        this.oss.am_rgb_65_swiper_10,
        this.oss.am_rgb_65_swiper_11,
        this.oss.am_rgb_65_swiper_12,
        this.oss.am_rgb_65_swiper_13
      ];
    },
    names02(): string[] {
      return this.isCN
        ? [
            "@guzy13",
            "@guzy13",
            "@guzy13",
            "@guzy13",
            "@shipkie",
            "@shipkie",
            "@夹克",
            "@夹克"
          ]
        : [
            "@guzy13",
            "@guzy13",
            "@guzy13",
            "@guzy13",
            "@shipkie",
            "@shipkie",
            "@夹克",
            "@夹克"
          ];
    },
    images03(): string[] {
      return [
        this.oss.am_rgb_65_swiper_14,
        this.oss.am_rgb_65_swiper_15,
        this.oss.am_rgb_65_swiper_16,
        this.oss.am_rgb_65_swiper_17,
        this.oss.am_rgb_65_swiper_18,
        this.oss.am_rgb_65_swiper_19
      ];
    },
    names03(): string[] {
      return this.isCN
        ? ["@熊闹闹", "@熊闹闹", "@熊闹闹", "@熊闹闹", "@熊闹闹", "@熊闹闹"]
        : ["@熊闹闹", "@熊闹闹", "@熊闹闹", "@熊闹闹", "@熊闹闹", "@熊闹闹"];
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_am_rgb_65);
    },
    onVerify() {
      window.open(this.$paths.verify + "cyberboard/", "_blank");
    }
  }
});
